import { render, staticRenderFns } from "./LikeMemberDetailLayout.vue?vue&type=template&id=6ef0814c&scoped=true"
import script from "./LikeMemberDetailLayout.vue?vue&type=script&lang=js"
export * from "./LikeMemberDetailLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ef0814c",
  null
  
)

export default component.exports